// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-header {
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background: #ff0055;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  padding: 0 1rem;
  z-index: 5;
}

main {
  margin-top: 5rem;
}

@media (min-width: 768px) {
  .main-header {
    justify-content: space-between;
  }
}`, "",{"version":3,"sources":["webpack://./src/shared/components/Navigation/MainHeader.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,MAAM;EACN,OAAO;EACP,mBAAmB;EACnB,yCAAyC;EACzC,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,8BAA8B;EAChC;AACF","sourcesContent":[".main-header {\n  width: 100%;\n  height: 4rem;\n  display: flex;\n  align-items: center;\n  position: fixed;\n  top: 0;\n  left: 0;\n  background: #ff0055;\n  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);\n  padding: 0 1rem;\n  z-index: 5;\n}\n\nmain {\n  margin-top: 5rem;\n}\n\n@media (min-width: 768px) {\n  .main-header {\n    justify-content: space-between;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
