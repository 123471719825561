// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.place-item {
  margin: 1rem 0;
}

.place-item__content {
  padding: 0;
}

.place-item__info {
  padding: 1rem;
  text-align: center;
}

.place-item__image {
  width: 100%;
  height: 12.5rem;
  margin-right: 1.5rem;
}

.place-item__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.place-item__info h2,
.place-item__info h3,
.place-item__info p {
  margin: 0 0 0.5rem 0;
}

.place-item__actions {
  padding: 1rem;
  text-align: center;
  border-top: 1px solid #ccc;
}

.place-item__modal-content {
  padding: 0;
}

.place-item__modal-actions {
  text-align: right;
}

.place-item__actions button,
.place-item__actions a {
  margin: 0.5rem;
}

.map-container {
  height: 15rem;
  width: 100%;
}

@media (min-width: 768px) {
  .place-item__image {
    height: 20rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/places/components/PlaceItem.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA;;;EAGE,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,0BAA0B;AAC5B;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,iBAAiB;AACnB;;AAEA;;EAEE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,WAAW;AACb;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".place-item {\n  margin: 1rem 0;\n}\n\n.place-item__content {\n  padding: 0;\n}\n\n.place-item__info {\n  padding: 1rem;\n  text-align: center;\n}\n\n.place-item__image {\n  width: 100%;\n  height: 12.5rem;\n  margin-right: 1.5rem;\n}\n\n.place-item__image img {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n\n.place-item__info h2,\n.place-item__info h3,\n.place-item__info p {\n  margin: 0 0 0.5rem 0;\n}\n\n.place-item__actions {\n  padding: 1rem;\n  text-align: center;\n  border-top: 1px solid #ccc;\n}\n\n.place-item__modal-content {\n  padding: 0;\n}\n\n.place-item__modal-actions {\n  text-align: right;\n}\n\n.place-item__actions button,\n.place-item__actions a {\n  margin: 0.5rem;\n}\n\n.map-container {\n  height: 15rem;\n  width: 100%;\n}\n\n@media (min-width: 768px) {\n  .place-item__image {\n    height: 20rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
