// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-control {
  margin: 1rem 0;
}

.form-control label,
.form-control input,
.form-control textarea {
  display: block;
}

.form-control label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-control input,
.form-control textarea {
  width: 100%;
  font: inherit;
  border: 1px solid #ccc;
  background: #f8f8f8;
  padding: 0.15rem 0.25rem;
}

.form-control input:focus,
.form-control textarea:focus {
  outline: none;
  background: #ebebeb;
  border-color: #510077;
}

.form-control--invalid label,
.form-control--invalid p {
  color: red;
}

.form-control--invalid input,
.form-control--invalid textarea {
  border-color: red;
  background: #ffd1d1;
}
`, "",{"version":3,"sources":["webpack://./src/shared/components/FormElements/Input.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;;;EAGE,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;;EAEE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,wBAAwB;AAC1B;;AAEA;;EAEE,aAAa;EACb,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;;EAEE,UAAU;AACZ;;AAEA;;EAEE,iBAAiB;EACjB,mBAAmB;AACrB","sourcesContent":[".form-control {\n  margin: 1rem 0;\n}\n\n.form-control label,\n.form-control input,\n.form-control textarea {\n  display: block;\n}\n\n.form-control label {\n  font-weight: bold;\n  margin-bottom: 0.5rem;\n}\n\n.form-control input,\n.form-control textarea {\n  width: 100%;\n  font: inherit;\n  border: 1px solid #ccc;\n  background: #f8f8f8;\n  padding: 0.15rem 0.25rem;\n}\n\n.form-control input:focus,\n.form-control textarea:focus {\n  outline: none;\n  background: #ebebeb;\n  border-color: #510077;\n}\n\n.form-control--invalid label,\n.form-control--invalid p {\n  color: red;\n}\n\n.form-control--invalid input,\n.form-control--invalid textarea {\n  border-color: red;\n  background: #ffd1d1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
