// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.users-list {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    width: 90%;
    max-width: 50rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }`, "",{"version":3,"sources":["webpack://./src/user/components/UsersList.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,UAAU;IACV,UAAU;IACV,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,eAAe;EACjB","sourcesContent":[".users-list {\n    list-style: none;\n    margin: 0 auto;\n    padding: 0;\n    width: 90%;\n    max-width: 50rem;\n    display: flex;\n    justify-content: center;\n    flex-wrap: wrap;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
