// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-item {
    margin: 1rem;
    width: calc(45% - 2rem);
    min-width: 17.5rem;
  }
  
  .user-item a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
    padding: 1rem;
    color: white;
    background: #292929;
  }
  
  .user-item a:hover,
  .user-item a:active {
    background: #ffd900;
  }
  
  .user-item__content {
    padding: 0;
  }
  
  .user-item__image {
    width: 4rem;
    height: 4rem;
    margin-right: 1rem;
  }
  
  .user-item__info h2 {
    font-size: 1.5rem;
    margin: 0 0 0.5rem 0;
    font-weight: normal;
    color: #ffd900;
  }
  
  .user-item:hover h2,
  .user-item:active h2,
  .user-item:hover h3,
  .user-item:active h3 {
    color: #292929;
  }
  
  .user-item__info h3 {
    margin: 0;
  }`, "",{"version":3,"sources":["webpack://./src/user/components/UserItem.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,uBAAuB;IACvB,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,qBAAqB;IACrB,aAAa;IACb,YAAY;IACZ,mBAAmB;EACrB;;EAEA;;IAEE,mBAAmB;EACrB;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,oBAAoB;IACpB,mBAAmB;IACnB,cAAc;EAChB;;EAEA;;;;IAIE,cAAc;EAChB;;EAEA;IACE,SAAS;EACX","sourcesContent":[".user-item {\n    margin: 1rem;\n    width: calc(45% - 2rem);\n    min-width: 17.5rem;\n  }\n  \n  .user-item a {\n    display: flex;\n    align-items: center;\n    width: 100%;\n    height: 100%;\n    text-decoration: none;\n    padding: 1rem;\n    color: white;\n    background: #292929;\n  }\n  \n  .user-item a:hover,\n  .user-item a:active {\n    background: #ffd900;\n  }\n  \n  .user-item__content {\n    padding: 0;\n  }\n  \n  .user-item__image {\n    width: 4rem;\n    height: 4rem;\n    margin-right: 1rem;\n  }\n  \n  .user-item__info h2 {\n    font-size: 1.5rem;\n    margin: 0 0 0.5rem 0;\n    font-weight: normal;\n    color: #ffd900;\n  }\n  \n  .user-item:hover h2,\n  .user-item:active h2,\n  .user-item:hover h3,\n  .user-item:active h3 {\n    color: #292929;\n  }\n  \n  .user-item__info h3 {\n    margin: 0;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
