// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.place-form {
  position: relative;
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 90%;
  max-width: 40rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
}
`, "",{"version":3,"sources":["webpack://./src/places/pages/PlaceForm.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,UAAU;EACV,gBAAgB;EAChB,yCAAyC;EACzC,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":[".place-form {\n  position: relative;\n  list-style: none;\n  margin: 0 auto;\n  padding: 1rem;\n  width: 90%;\n  max-width: 40rem;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);\n  border-radius: 6px;\n  background: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
