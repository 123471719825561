// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-links li {
  margin: 1rem;
}

.nav-links a {
  border: 1px solid transparent;
  color: #292929;
  text-decoration: none;
  padding: 0.5rem;
}

.nav-links a:hover,
.nav-links a:active,
.nav-links a.active {
  background: #f8df00;
  border-color: #292929;
  color: #292929;
}

.nav-links button {
  cursor: pointer;
  border: 1px solid #292929;
  color: #292929;
  background: transparent;
  padding: 0.5rem;
  font: inherit;
}

.nav-links button:focus {
  outline: none;
}

.nav-links button:hover,
.nav-links button:active {
  background: #292929;
  color: white;
}

@media (min-width: 768px) {
  .nav-links {
    flex-direction: row;
  }

  .nav-links li {
    margin: 0 0.5rem;
  }

  .nav-links a {
    color: white;
    text-decoration: none;
  }

  .nav-links button {
    border: 1px solid white;
    color: white;
    background: transparent;
  }

  .nav-links button:hover,
  .nav-links button:active {
    background: #f8df00;
    color: #292929;
  }
}
`, "",{"version":3,"sources":["webpack://./src/shared/components/Navigation/NavLinks.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,SAAS;EACT,UAAU;EACV,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,6BAA6B;EAC7B,cAAc;EACd,qBAAqB;EACrB,eAAe;AACjB;;AAEA;;;EAGE,mBAAmB;EACnB,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,yBAAyB;EACzB,cAAc;EACd,uBAAuB;EACvB,eAAe;EACf,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;;EAEE,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE;IACE,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,YAAY;IACZ,qBAAqB;EACvB;;EAEA;IACE,uBAAuB;IACvB,YAAY;IACZ,uBAAuB;EACzB;;EAEA;;IAEE,mBAAmB;IACnB,cAAc;EAChB;AACF","sourcesContent":[".nav-links {\n  list-style: none;\n  margin: 0;\n  padding: 0;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.nav-links li {\n  margin: 1rem;\n}\n\n.nav-links a {\n  border: 1px solid transparent;\n  color: #292929;\n  text-decoration: none;\n  padding: 0.5rem;\n}\n\n.nav-links a:hover,\n.nav-links a:active,\n.nav-links a.active {\n  background: #f8df00;\n  border-color: #292929;\n  color: #292929;\n}\n\n.nav-links button {\n  cursor: pointer;\n  border: 1px solid #292929;\n  color: #292929;\n  background: transparent;\n  padding: 0.5rem;\n  font: inherit;\n}\n\n.nav-links button:focus {\n  outline: none;\n}\n\n.nav-links button:hover,\n.nav-links button:active {\n  background: #292929;\n  color: white;\n}\n\n@media (min-width: 768px) {\n  .nav-links {\n    flex-direction: row;\n  }\n\n  .nav-links li {\n    margin: 0 0.5rem;\n  }\n\n  .nav-links a {\n    color: white;\n    text-decoration: none;\n  }\n\n  .nav-links button {\n    border: 1px solid white;\n    color: white;\n    background: transparent;\n  }\n\n  .nav-links button:hover,\n  .nav-links button:active {\n    background: #f8df00;\n    color: #292929;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
