// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-navigation__menu-btn {
  width: 3rem;
  height: 3rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 2rem;
  cursor: pointer;
}

.main-navigation__menu-btn span {
  display: block;
  width: 3rem;
  height: 2.5px;
  background: white;
}

.main-navigation__title {
  color: white;
}

.main-navigation__title a {
  text-decoration: none;
  color: white;
}

.main-navigation__header-nav {
  display: none;
}

.main-navigation__drawer-nav {
  height: 100%;
}

@media (min-width: 768px) {
  .main-navigation__menu-btn {
    display: none;
  }

  .main-navigation__header-nav {
    display: block;
  }
}`, "",{"version":3,"sources":["webpack://./src/shared/components/Navigation/MainNavigation.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,6BAA6B;EAC7B,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,cAAc;EAChB;AACF","sourcesContent":[".main-navigation__menu-btn {\n  width: 3rem;\n  height: 3rem;\n  background: transparent;\n  border: none;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-around;\n  margin-right: 2rem;\n  cursor: pointer;\n}\n\n.main-navigation__menu-btn span {\n  display: block;\n  width: 3rem;\n  height: 2.5px;\n  background: white;\n}\n\n.main-navigation__title {\n  color: white;\n}\n\n.main-navigation__title a {\n  text-decoration: none;\n  color: white;\n}\n\n.main-navigation__header-nav {\n  display: none;\n}\n\n.main-navigation__drawer-nav {\n  height: 100%;\n}\n\n@media (min-width: 768px) {\n  .main-navigation__menu-btn {\n    display: none;\n  }\n\n  .main-navigation__header-nav {\n    display: block;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
